$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1400px
);

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: $grid-breakpoints(xs);
  --breakpoint-sm: $grid-breakpoints(sm);
  --breakpoint-md: $grid-breakpoints(md);
  --breakpoint-lg: $grid-breakpoints(lg);
  --breakpoint-xl: $grid-breakpoints(xl);
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

@font-face {
  font-family: 'Gotham Pro';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/GothamPro-Light.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Pro';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/GothamPro.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Pro';
  font-weight: normal;
  font-style: italic;
  src: url('../fonts/GothamPro-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Pro';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/GothamPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Pro';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/GothamPro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Pro';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/GothamPro-Black.woff') format('woff');
}

//GENERAL//
$header-padding: 66px;

//FONTS//

$font-stack-primary: "Gotham Pro", sans-serif;
$font-size: 14px;
$p-line-height: 1.825;

//FONTS//

$bg-body: #ffffff;
$color-primary: #00D2D4;
$color-accent: #4988FD;
$color-gray: #9c9c9d;
$color-dark: #232427;

//HEADING SIZING
$h1-font-size: 4em;
$h2-font-size: $h1-font-size - $h1-font-size * 20 / 100;
$h3-font-size: $h2-font-size - $h2-font-size * 20 / 100;
$h4-font-size: $h3-font-size - $h3-font-size * 20 / 100;
$h5-font-size: $h4-font-size - $h4-font-size * 25 / 100;
$h6-font-size: $h5-font-size - $h5-font-size * 25 / 100;

//MIXINS//

@mixin overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin flex-grid($col) {
  flex: 0 0 calc(100% / #{$col});
  max-width: calc(100% / #{$col});
}

@mixin grid($percentage) {
  flex: 0 0 $percentage;
  max-width: $percentage;
}

@mixin flex($col) {
  flex: 0 0 calc(100% / #{$col});
}

@mixin svg($desiredWidth, $originalWidth, $originalHeight) {
  width: $desiredWidth;
  height: calc(#{$desiredWidth} * #{$originalHeight} / #{$originalWidth});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $desiredWidth calc(#{$desiredWidth} * #{$originalHeight} / #{$originalWidth});
}

@mixin height($desiredWidth, $originalWidth, $originalHeight) {
  height: calc(#{$desiredWidth} * #{$originalHeight} / #{$originalWidth});
}

@mixin thumb {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin boxed($width) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
}