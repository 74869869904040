/*************************************************************
 Author     : Vivid Motion @ http://vividmotion.co/
 *************************************************************/

@import 'custom';

@media all and (min-width: 576px) {
  //VARIABLES//



  //VARIABLES END//
}

@media all and (min-width: 768px) {
  //VARIABLES//

  $header-padding: 146px;

  //VARIABLES END//

  .navbar-expand-md {
    padding: 3rem 0;
  }

  .navbar-nav {
    .nav-item {
      padding: 0 0 0 2.5rem;
    }
    .nav-item > .nav-link {
      padding: 0;
    }
    .nav-item > .nav-link:before {
      content: "";

      position: absolute;
      left: 0;
      bottom: 0;

      width: 100%;
      height: 2px;

      transform: scaleX(0);
      transform-origin: left center;

      background-color: currentColor;
    }
    .nav-item.active > .nav-link:before {
      content: "";

      position: absolute;
      left: 0;
      bottom: 0;

      width: 100%;
      height: 2px;

      transform: unset;
      transform-origin: left center;

      background-color: currentColor;
    }
    .nav-item > .nav-link:hover:before {
      animation: footer-link-hover2 700ms cubic-bezier(.58, .3, .005, 1) 0s;
    }
  }

  .section-first {
    padding-top: calc(#{$header-padding} + 2.5rem);
  }

  body {
    //padding-top: $header-padding;
  }

  .hero {
    //Typography
    h1 {
      font-size: 3.5em;
    }
    h2 {
      font-size: 1.5em;
    }
  }

  //Grid mods
  $grid-gutter: 15px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  .link,
  .btn {
    min-width: 12rem;
  }
}

@media all and (min-width: 1024px) {
  //VARIABLES//



  //VARIABLES END//

  .one-two-three-block {
    max-width: none;
    margin-left: unset;
    margin-right: unset;
  }

  .icon-block {
    max-width: none;
    margin-left: unset;
    margin-right: unset;
  }
}

@media all and (min-width: 1280px) {
  //VARIABLES//



  //VARIABLES END//

  //Grid mods
  $grid-gutter: 15px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  .column-slide {
    &-column {
      @include flex-grid(2);
    }
  }

  .cta-block {
    &-content {
      padding: 4rem;
    }
  }

  .column-slide-column {
    min-height: 80vh;
  }

  .column-slide-column[data-section-bg] {
    padding: unset;
  }
}

@media all and (min-width: 1440px) {
  //VARIABLES//



  //VARIABLES END//
}

@media all and (min-width: 1650px) {
  //VARIABLES//



  //VARIABLES END//

  //Grid mods
  $grid-gutter: 15px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  $font-size: 16px;

  body {
    font-size: $font-size;
  }
}

@media all and (max-width: 1199px) {

}

@media all and (max-width: 1024px) {
  .one-two-three-block {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .icon-block {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media all and (max-width: 768px) {

}

@media all and (max-width: 767px) {
  h1 {
    font-size: 2.4em;
    line-height: 1;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.8em;
  }

  h4 {
    font-size: 1.6em;
  }

  h5 {
    font-size: 1.4em;
  }

  h6 {
    font-size: 1.2em;
  }

  .navbar-menu {
    position: absolute;
    top: 100%;

    right: 1em;
    left: 1em;

    background-color: white;
    border: 1px solid #eaeaea;

    padding: 5px 0;

    margin-top: 10px;

    border-radius: 0.25rem;

    display: none;

    text-align: center;

    opacity: 0;
    transition: opacity .3s ease;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;

    text-align: left;
    .nav-item > .nav-link {
      padding: 8px 16px;

      margin: 0 -1px;

      font-size: 0.875em;

      color: black;

      transition: all .2s ease;
      .original-hover div,
      .fake-hover div {
        transition: none;
      }
    }
    .nav-item > .nav-link:hover {
      background-color: $color-primary;
      color: white;
    }
    .nav-item > .btn {
      display: block;

      margin-bottom: -6px;

      border-radius: 0 0 0.25rem 0.25rem;
    }
  }

  .navbar-toggler {
    order: 1;
    margin-left: auto;
    border: 0;
    padding: 0;

    pointer-events: auto;

    border-radius: 0;
    &:hover {
      background-color: black;
      .navbar-toggler-icon:before {
        color: white;
      }
    }
  }

  .navbar-toggler,
  .navbar-brand {
    z-index: 1061;
  }

  .menu-open {
    opacity: 1;
  }

  .navbar-toggler-icon {
    position: relative;
  }

  .menu-is-open {
    .navbar-toggler-icon {
      background-color: black;
    }
    .navbar-toggler-icon:before {
      color: white;
    }
    .navbar-toggler:hover .navbar-toggler-icon:before {
      color: white;
    }
  }

  .navbar-toggler-icon:before {
    content: "\f141";
    font-family: "Font Awesome\ 5 Free";
    font-weight: 900;

    color: $color-dark;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media all and (max-width: 576px) {

}

@media all and (max-width: 320px) {

}