/*************************************************************
 Author     : Vivid Motion @ http://vividmotion.co/
 *************************************************************/
@import 'custom';

body {
  font-family: $font-stack-primary;
  font-size: $font-size;
  background: $bg-body;
  color: $color-dark;
}

html, body {
  height: 100%;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  line-height: $p-line-height;

  margin-bottom: 0;
}

a {
  color: #000000;
}

a:hover,
a:active {
  color: #000000;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

/*** Misc Start ***/

.section {
  padding: 5rem 0;
  > .d-flex {
    margin: -5rem 0;
    padding: 5rem 0;
  }
}

//Grid mods
$grid-gutter: 15px;

.container {
  width: 100%;

  max-width: 1200px;

  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
}

.container {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
}

.row {
  margin-left: -$grid-gutter;
  margin-right: -$grid-gutter;
  > .col,
  > [class*="col-"] {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}

.container-fluid {
  width: 100%;
}

.page-content {
  padding: 6rem 0;
}

.overflow-h {
  overflow: hidden;
}

.bg-white {
  background-color: white;
}

*:focus {
  outline: 0 !important;
}

/*** Misc End ***/

/*** Header Start ***/

header {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;

  pointer-events: none;
}

.navbar-expand-md {
  background-color: transparent;

  border: 0;
  border-radius: 0;

  margin: 0;
  z-index: 1051;
}

.navbar-nav {
  .nav-item > .nav-link {
    position: relative;
    line-height: 1.5;
    color: $color-dark;

    pointer-events: auto;
  }
  .nav-item > .nav-link:hover {
    background-color: transparent;
  }
  .nav-item > .nav-link:focus {
    background-color: transparent;
  }
}

.header-invert {
  .navbar-nav .nav-item > .nav-link {
    color: white;
  }
  .navbar-brand {
    color: white;
  }
}

.navbar-brand {
  pointer-events: auto;
  img {
    max-height: 2.5rem;
  }
}

/*** Header End ***/

/*** Hero Start ***/

.hero {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  > .d-flex {
    width: 100%;

    padding: 8rem 0;
  }
  > .d-flex > .d-flex {
    width: 100%;
  }
  //Typography
  h1 {
    font-weight: 700;
    font-size: 2.5em;
  }
  h2 {
    font-weight: 400;
    font-size: 1em;
  }
}

#hero-home {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $color-dark;
  //color: white;
  > .d-flex {
    min-height: 75vh;
    padding-bottom: 12.5rem;
  }
  p {
    @include boxed(45rem);
    margin-top: 1rem;
    font-size: 1.125em;
  }
  .btn {
    margin-top: 2rem;
  }
  .col-lg-12 {
    pointer-events: none;
  }
  .col-lg-12 > * {
    pointer-events: auto;
  }
}

#hero-about {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $color-dark;
  color: white;
  > .d-flex {
    //min-height: 50vh;
  }
  p {
    @include boxed(55rem);
    margin-top: 1rem;
    font-size: 1.125em;
  }
  .btn {
    margin-top: 2rem;
  }
}

#hero-contact {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $color-dark;
  color: white;
  > .d-flex {
    //min-height: 50vh;
  }
  p {
    @include boxed(45rem);
    margin-top: 1rem;
    font-size: 1.125em;
  }
  .btn {
    margin-top: 2rem;
  }
}

.hero-contact-bg {
  background: linear-gradient(to right bottom, $color-primary 50%, $color-accent);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-dark, 0.5);
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 1;
}

/*** Hero End ***/

/*** Main Start ***/

.section-img-wide {
  padding-bottom: 56.25%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.5rem;
}

.section-content {
  @include boxed(70rem);
  &-label {
    opacity: 0.4;
    margin-bottom: 1rem;
  }
  &-title {
    font-size: 1.5em;
  }
}

.section-title {
  @include boxed(60rem);
  &-label {
    opacity: 0.4;
    margin-bottom: 1rem;
  }
  &-title {
    font-size: 2em;
    font-weight: 400;
  }
}

.shot {
  position: relative;
  overflow: hidden;

  border-radius: 0.5rem;
  &:before {
    content: "";
    display: block;
    padding-bottom: 100% / (16 / 9);
  }
  & > :first-child {
    position: absolute;
    top: 0;
    left: 3rem;
    right: 3rem;
    height: 100%;
  }
  &-image {
    display: flex;
    flex-direction: column;
  }
  img {
    max-width: 100%;
    margin-top: auto;

    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.slide-content {
  h3 {
    margin-bottom: 1rem;
    font-weight: 500;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li + li {
      margin-top: 0.75rem;
    }
  }
  .link {
    margin-top: 2rem;
  }
  .link-underline-2 {
    margin-top: 2rem;
  }
}

.column-slide {
  display: flex;
  flex-flow: row wrap;
  &-column {
    @include flex-grid(1);
    //min-height: 80vh;
    padding: 6rem 10%;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-column[data-section-bg] {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

    padding: 0;
    padding-bottom: 56.25%;
  }
}

.link-underline {
  position: relative;

  line-height: 1.5;

  font-weight: 500;

  display: inline-flex;
  align-items: center;
  > .span-text {
    position: relative;
    display: inline-block;

    margin-right: 1rem;
  }
  > .span-text:before {
    content: "";

    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 2px;

    background-color: currentColor;
  }
  &:hover > .span-text:before {
    animation: footer-link-hover 700ms cubic-bezier(.58, .3, .005, 1) 0s;
  }
}

@keyframes footer-link-hover {
  0% {
    transform: translateX(0)
  }
  50% {
    transform: translateX(100%)
  }
  50.01% {
    transform: translateX(-100%)
  }
  to {
    transform: translateX(0)
  }
}

.link-underline-2 {
  position: relative;

  font-weight: 500;

  line-height: 1.5;

  display: inline-flex;
  align-items: center;
  > .span-text {
    position: relative;
    display: inline-block;

    margin-right: 1rem;
  }
  > .span-text:before {
    content: "";

    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 2px;

    transform: scaleX(0);
    transform-origin: left center;

    background-color: currentColor;
  }
  &:hover > .span-text:before {
    animation: footer-link-hover2 700ms cubic-bezier(.58, .3, .005, 1) 0s;
  }
}

@keyframes footer-link-hover2 {
  0% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left center;
  }
  50.01% {
    transform: scaleX(1);
    transform-origin: right center;
  }
  to {
    transform: scaleX(0);
    transform-origin: right center;
  }
}

.bg-dark {
  background-color: $color-dark !important;
  color: white;
  .link-underline,
  .link-underline-2 {
    color: white;
  }
}

.bg-primary {
  background-color: $color-primary !important;
  color: white;
  .link-underline,
  .link-underline-2 {
    color: white;
  }
}

.bg-accent {
  background-color: $color-accent !important;
  color: white;
  .link-underline,
  .link-underline-2 {
    color: white;
  }
}

.swiper-testimonials-wrapper {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;

  padding-bottom: 7rem;
  .swiper-pagination {
    left: 50%;
    bottom: 0;

    transform: translate(-50%, 0);
  }
  .swiper-pagination-bullet {
    background-color: transparent;
    width: 4rem;
    height: 4rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin: 0 0.25rem;

    opacity: 1;

    transition: background-color 300ms ease, color 300ms ease;
  }
  .swiper-pagination-bullet-active {
    background-color: $color-primary;
    color: white;
  }
}

.swiper-testimonials {
  position: relative;
  overflow: hidden;
  .swiper-slide {
    opacity: 0 !important;
  }
  .swiper-slide-active {
    opacity: 1 !important;
  }
}

.testimonial-slide {
  @include boxed(50rem);
  &-author {
    margin-bottom: 2rem;
    text-align: center;
  }
  &-thumb {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 1rem;
  }
  &-author-name {
    font-size: 1.125em;
  }
  &-author-title {
    font-size: 0.75em;
    opacity: 0.6;
  }
  &-content {
    text-align: center;
    p {
      position: relative;
      font-size: 1.25em;
      line-height: 1.625;
    }
    p:before {
      content: "\201C";
    }
    p:after {
      content: "\201D";
    }
  }
}

img[data-bg="true"] {
  display: none;
}

.cta-block {
  position: relative;
  border-radius: 0.5rem;
  padding-bottom: 50%;
  min-height: 500px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  //border: 1px solid #eaeaea;
  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 50rem;

    padding: 3rem 2rem;
    h3 {
      font-size: 2em;
    }
    p {
      max-width: 35rem;

      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
}

.chevron {
  background-color: currentColor;

  width: 3rem;
  height: 3rem;

  margin-right: 1.25rem;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  top: -1px;
}

.chevron:before {
  content: "";

  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: white;

  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;

  left: -1px;

  position: relative;

  transform: rotate(45deg);

  mix-blend-mode: difference;
}

.chevron-down:before {
  transform: rotate(135deg);

  left: 0;
  top: -1px;
}

.icon-block {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  &-icon {
    margin-bottom: 1.5rem;
    img {
      max-height: 4.5rem;
    }
  }
  &-content {
    h4 {
      margin-bottom: 1rem;

      font-size: 1.5em;
    }
  }
}

.one-two-three-block {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  &-number {
    font-size: 2.5em;
    font-weight: 500;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 6rem;
    height: 6rem;

    border-radius: 100%;
    border-bottom: 6px solid currentColor;

    padding-top: 6px;
    padding-right: 4px;

    background: linear-gradient(to top, $color-accent, $color-primary);
    color: white;

    margin-bottom: 1.5rem;

    line-height: 1;
  }
  &-content {
    h4 {
      margin-bottom: 1rem;

      font-size: 1.5em;
    }
  }
}

.our-values-block {
  &-content {
    h4 {
      margin-bottom: 1rem;

      font-size: 1em;
      font-weight: 500;
    }
    p {
      font-size: 0.875em;
    }
  }
}

.form-contact {
  padding: 2rem;
  background-color: white;
  border-radius: 1rem;
  max-width: 40rem;

  margin-left: auto;
  margin-right: auto;
  margin-top: -5rem;

  box-shadow: 0 2px 3px -1px rgba(black, 0.2);
  .form-group {
    margin-bottom: 0;
  }
  .form-row + .form-row {
    margin-top: 1rem;
  }
  .btn {
    padding: 1rem 2rem;
  }
}

.form-control {
  height: 3.5rem;
  padding: calc(0.75rem + 2px) 1rem 0.75rem;

  border-color: #eaeaea;
  color: $color-dark;
  &:focus::placeholder {
    opacity: 0.5;
  }
}

.section-heading {
  margin-bottom: 3rem;
  h1 {
    font-size: 3em;
    font-weight: 700;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.5em;
  }
  p {
    opacity: 0.6;
    @include boxed(40rem);
  }
}

.section-first {
  padding-top: calc(#{$header-padding} + 2.5rem);
}

.table-jobs {
  &-tr-flex {
    display: flex;
    max-width: 1200px;

    padding-left: 15px;
    padding-right: 15px;

    margin-left: auto;
    margin-right: auto;
  }
  &-tr:nth-child(even) {
    background-color: #edf2fd;
  }
  &-td:nth-child(1) {
    flex: 2;
  }
  &-td:nth-child(2) {
    flex: 2;
  }
  &-td {
    flex: 1;

    color: #6582b1;
    font-size: 0.875em;

    padding: 0.75rem 0;
  }
  &-td:nth-child(3) {
    display: flex;
    align-items: center;
    img {
      margin-right: 0.75rem;
      position: relative;
      top: -1px;
    }
  }
  &-td a {
    display: block;
    color: $color-accent;
  }
  &-td a:hover {
    text-decoration: underline;
  }
}

.section-page-content {
  max-width: 50rem;

  margin-left: auto;
  margin-right: auto;
  h1 {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.25em;
    font-weight: 400;

    margin-bottom: 1rem;

    line-height: 1.6;

    opacity: 0.6;
  }
  p {
    font-size: 0.875em;
  }
  p + p {
    margin-top: 0.75rem;
  }
  ul {
    font-size: 0.875em;
    margin: 1rem 0;
    padding: 0 0 0 20px;
  }
  ul li {
    list-style: none;
    position: relative;
    padding-left: 1rem;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;

      width: 0.4rem;
      height: 0.4rem;

      border-radius: 50%;

      background-color: rgba($color-accent, 0.5);
    }
  }
  ul li + li {
    margin-top: 0.75rem;
  }
}

.apply {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2rem;
  margin: 2rem -15px;

  background-color: #edf2fd;
  color: #6582b1;
  border-radius: 1rem;
  h4 {
    font-size: 1.25em;
    font-weight: 400;
  }
}

//h6, .text-center {
//  font-size: 0.8em;
//}
//
//h5, .blue {
//  font-size: 0.9em;
//  color: darkblue;
//}

/*** Main End ***/

/*** Buttons Start ***/

.btn {
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 1.25rem 2rem;

  min-width: 8rem;

  font-weight: 500;
  > span {
    position: relative;
    overflow: hidden;

    display: inline-block;
    line-height: 1;

    top: 0.125rem;
  }
  > span > span {
    display: inline-block;
    margin: 0 1.5px;
  }
  > span > span:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;

    display: inline-block;

    transform: translateY(-100%);
  }
  &:hover > span > span {
    animation: btn-hover-text .6s cubic-bezier(0.77, 0, 0.175, 1);
  }
  &-primary,
  &-primary:focus {
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
  }
  &-primary:hover,
  &-primary:active:focus {
    background-color: lighten($color-primary, 5%);
    border-color: lighten($color-primary, 5%);
    color: white;
  }
  &-accent,
  &-accent:focus {
    background-color: $color-accent;
    border-color: $color-accent;
    color: white;
  }
  &-accent:hover,
  &-accent:active:focus {
    background-color: lighten($color-accent, 10%);
    border-color: lighten($color-accent, 10%);
    color: white;
  }
}

.link {
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0.35rem 2rem;

  min-width: 8rem;

  font-weight: 400;
  .chevron {
    margin-left: 1rem;

    width: 2rem;
    height: 2rem;
  }
}

@keyframes btn-hover-text {
  to {
    transform: translateY(100%)
  }
}

/*** Buttons End ***/

/*** Footer Start ***/

footer {
  padding: 2rem 0;

  border-top: 1px solid #eaeaea;
}

.footer-brand {
  display: inline-block;

  font-size: 1em;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  li a {
    font-size: 0.875em;
    font-weight: 400;
  }
  li a:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
}

.footer-links-inline {
  margin: 0 -8px;
  li {
    display: inline-block;
    padding: 0 8px;
  }
}

.footer-social {
  margin: 0 -2px;
  li {
    padding: 0 2px;
  }
  li a {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: rgba($color-dark, 0.75);
    color: white;

    width: 1.5rem;
    height: 1.5rem;

    font-size: 0.65rem;
  }
  li a:hover {
    background-color: $color-primary;
    text-decoration: none;
  }
}

.copyright {
  color: rgba($color-dark, 0.6);

  font-size: 0.75em;
}

.copyright + ul {
  margin: 0 -4px;

  position: relative;
  top: -2px;
}

.copyright + ul li {
  padding: 0 4px;
}

.copyright + ul li a {
  color: rgba($color-dark, 0.6);

  font-size: 0.75em;
  font-weight: 400;
}

/*** Footer End ***/

/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('../img/flags.png') no-repeat;

  filter: saturate(75%) brightness(125%);
}


.flag.flag-ad {
  background-position: -24px 0;
}

.flag.flag-ae {
  background-position: -48px 0;
}

.flag.flag-af {
  background-position: -72px 0;
}

.flag.flag-ag {
  background-position: -96px 0;
}

.flag.flag-ai {
  background-position: -120px 0;
}

.flag.flag-al {
  background-position: -144px 0;
}

.flag.flag-am {
  background-position: -168px 0;
}

.flag.flag-an {
  background-position: -192px 0;
}

.flag.flag-ao {
  background-position: -216px 0;
}

.flag.flag-ar {
  background-position: -240px 0;
}

.flag.flag-as {
  background-position: -264px 0;
}

.flag.flag-at {
  background-position: -288px 0;
}

.flag.flag-au {
  background-position: -312px 0;
}

.flag.flag-aw {
  background-position: -336px 0;
}

.flag.flag-ax {
  background-position: -360px 0;
}

.flag.flag-az {
  background-position: 0 -24px;
}

.flag.flag-ba {
  background-position: -24px -24px;
}

.flag.flag-bb {
  background-position: -48px -24px;
}

.flag.flag-bd {
  background-position: -72px -24px;
}

.flag.flag-be {
  background-position: -96px -24px;
}

.flag.flag-bf {
  background-position: -120px -24px;
}

.flag.flag-bg {
  background-position: -144px -24px;
}

.flag.flag-bh {
  background-position: -168px -24px;
}

.flag.flag-bi {
  background-position: -192px -24px;
}

.flag.flag-bj {
  background-position: -216px -24px;
}

.flag.flag-bl {
  background-position: -240px -24px;
}

.flag.flag-bm {
  background-position: -264px -24px;
}

.flag.flag-bn {
  background-position: -288px -24px;
}

.flag.flag-bo {
  background-position: -312px -24px;
}

.flag.flag-br {
  background-position: -336px -24px;
}

.flag.flag-bs {
  background-position: -360px -24px;
}

.flag.flag-bt {
  background-position: 0 -48px;
}

.flag.flag-bw {
  background-position: -24px -48px;
}

.flag.flag-by {
  background-position: -48px -48px;
}

.flag.flag-bz {
  background-position: -72px -48px;
}

.flag.flag-ca {
  background-position: -96px -48px;
}

.flag.flag-cd {
  background-position: -120px -48px;
}

.flag.flag-cf {
  background-position: -144px -48px;
}

.flag.flag-cg {
  background-position: -168px -48px;
}

.flag.flag-ch {
  background-position: -192px -48px;
}

.flag.flag-ci {
  background-position: -216px -48px;
}

.flag.flag-ck {
  background-position: -240px -48px;
}

.flag.flag-cl {
  background-position: -264px -48px;
}

.flag.flag-cm {
  background-position: -288px -48px;
}

.flag.flag-cn {
  background-position: -312px -48px;
}

.flag.flag-co {
  background-position: -336px -48px;
}

.flag.flag-cr {
  background-position: -360px -48px;
}

.flag.flag-cu {
  background-position: 0 -72px;
}

.flag.flag-cv {
  background-position: -24px -72px;
}

.flag.flag-cw {
  background-position: -48px -72px;
}

.flag.flag-cy {
  background-position: -72px -72px;
}

.flag.flag-cz {
  background-position: -96px -72px;
}

.flag.flag-de {
  background-position: -120px -72px;
}

.flag.flag-dj {
  background-position: -144px -72px;
}

.flag.flag-dk {
  background-position: -168px -72px;
}

.flag.flag-dm {
  background-position: -192px -72px;
}

.flag.flag-do {
  background-position: -216px -72px;
}

.flag.flag-dz {
  background-position: -240px -72px;
}

.flag.flag-ec {
  background-position: -264px -72px;
}

.flag.flag-ee {
  background-position: -288px -72px;
}

.flag.flag-eg {
  background-position: -312px -72px;
}

.flag.flag-eh {
  background-position: -336px -72px;
}

.flag.flag-er {
  background-position: -360px -72px;
}

.flag.flag-es {
  background-position: 0 -96px;
}

.flag.flag-et {
  background-position: -24px -96px;
}

.flag.flag-eu {
  background-position: -48px -96px;
}

.flag.flag-fi {
  background-position: -72px -96px;
}

.flag.flag-fj {
  background-position: -96px -96px;
}

.flag.flag-fk {
  background-position: -120px -96px;
}

.flag.flag-fm {
  background-position: -144px -96px;
}

.flag.flag-fo {
  background-position: -168px -96px;
}

.flag.flag-fr {
  background-position: -192px -96px;
}

.flag.flag-ga {
  background-position: -216px -96px;
}

.flag.flag-gb {
  background-position: -240px -96px;
}

.flag.flag-gd {
  background-position: -264px -96px;
}

.flag.flag-ge {
  background-position: -288px -96px;
}

.flag.flag-gg {
  background-position: -312px -96px;
}

.flag.flag-gh {
  background-position: -336px -96px;
}

.flag.flag-gi {
  background-position: -360px -96px;
}

.flag.flag-gl {
  background-position: 0 -120px;
}

.flag.flag-gm {
  background-position: -24px -120px;
}

.flag.flag-gn {
  background-position: -48px -120px;
}

.flag.flag-gq {
  background-position: -72px -120px;
}

.flag.flag-gr {
  background-position: -96px -120px;
}

.flag.flag-gs {
  background-position: -120px -120px;
}

.flag.flag-gt {
  background-position: -144px -120px;
}

.flag.flag-gu {
  background-position: -168px -120px;
}

.flag.flag-gw {
  background-position: -192px -120px;
}

.flag.flag-gy {
  background-position: -216px -120px;
}

.flag.flag-hk {
  background-position: -240px -120px;
}

.flag.flag-hn {
  background-position: -264px -120px;
}

.flag.flag-hr {
  background-position: -288px -120px;
}

.flag.flag-ht {
  background-position: -312px -120px;
}

.flag.flag-hu {
  background-position: -336px -120px;
}

.flag.flag-ic {
  background-position: -360px -120px;
}

.flag.flag-id {
  background-position: 0 -144px;
}

.flag.flag-ie {
  background-position: -24px -144px;
}

.flag.flag-il {
  background-position: -48px -144px;
}

.flag.flag-im {
  background-position: -72px -144px;
}

.flag.flag-in {
  background-position: -96px -144px;
}

.flag.flag-iq {
  background-position: -120px -144px;
}

.flag.flag-ir {
  background-position: -144px -144px;
}

.flag.flag-is {
  background-position: -168px -144px;
}

.flag.flag-it {
  background-position: -192px -144px;
}

.flag.flag-je {
  background-position: -216px -144px;
}

.flag.flag-jm {
  background-position: -240px -144px;
}

.flag.flag-jo {
  background-position: -264px -144px;
}

.flag.flag-jp {
  background-position: -288px -144px;
}

.flag.flag-ke {
  background-position: -312px -144px;
}

.flag.flag-kg {
  background-position: -336px -144px;
}

.flag.flag-kh {
  background-position: -360px -144px;
}

.flag.flag-ki {
  background-position: 0 -168px;
}

.flag.flag-km {
  background-position: -24px -168px;
}

.flag.flag-kn {
  background-position: -48px -168px;
}

.flag.flag-kp {
  background-position: -72px -168px;
}

.flag.flag-kr {
  background-position: -96px -168px;
}

.flag.flag-kw {
  background-position: -120px -168px;
}

.flag.flag-ky {
  background-position: -144px -168px;
}

.flag.flag-kz {
  background-position: -168px -168px;
}

.flag.flag-la {
  background-position: -192px -168px;
}

.flag.flag-lb {
  background-position: -216px -168px;
}

.flag.flag-lc {
  background-position: -240px -168px;
}

.flag.flag-li {
  background-position: -264px -168px;
}

.flag.flag-lk {
  background-position: -288px -168px;
}

.flag.flag-lr {
  background-position: -312px -168px;
}

.flag.flag-ls {
  background-position: -336px -168px;
}

.flag.flag-lt {
  background-position: -360px -168px;
}

.flag.flag-lu {
  background-position: 0 -192px;
}

.flag.flag-lv {
  background-position: -24px -192px;
}

.flag.flag-ly {
  background-position: -48px -192px;
}

.flag.flag-ma {
  background-position: -72px -192px;
}

.flag.flag-mc {
  background-position: -96px -192px;
}

.flag.flag-md {
  background-position: -120px -192px;
}

.flag.flag-me {
  background-position: -144px -192px;
}

.flag.flag-mf {
  background-position: -168px -192px;
}

.flag.flag-mg {
  background-position: -192px -192px;
}

.flag.flag-mh {
  background-position: -216px -192px;
}

.flag.flag-mk {
  background-position: -240px -192px;
}

.flag.flag-ml {
  background-position: -264px -192px;
}

.flag.flag-mm {
  background-position: -288px -192px;
}

.flag.flag-mn {
  background-position: -312px -192px;
}

.flag.flag-mo {
  background-position: -336px -192px;
}

.flag.flag-mp {
  background-position: -360px -192px;
}

.flag.flag-mq {
  background-position: 0 -216px;
}

.flag.flag-mr {
  background-position: -24px -216px;
}

.flag.flag-ms {
  background-position: -48px -216px;
}

.flag.flag-mt {
  background-position: -72px -216px;
}

.flag.flag-mu {
  background-position: -96px -216px;
}

.flag.flag-mv {
  background-position: -120px -216px;
}

.flag.flag-mw {
  background-position: -144px -216px;
}

.flag.flag-mx {
  background-position: -168px -216px;
}

.flag.flag-my {
  background-position: -192px -216px;
}

.flag.flag-mz {
  background-position: -216px -216px;
}

.flag.flag-na {
  background-position: -240px -216px;
}

.flag.flag-nc {
  background-position: -264px -216px;
}

.flag.flag-ne {
  background-position: -288px -216px;
}

.flag.flag-nf {
  background-position: -312px -216px;
}

.flag.flag-ng {
  background-position: -336px -216px;
}

.flag.flag-ni {
  background-position: -360px -216px;
}

.flag.flag-nl {
  background-position: 0 -240px;
}

.flag.flag-no {
  background-position: -24px -240px;
}

.flag.flag-np {
  background-position: -48px -240px;
}

.flag.flag-nr {
  background-position: -72px -240px;
}

.flag.flag-nu {
  background-position: -96px -240px;
}

.flag.flag-nz {
  background-position: -120px -240px;
}

.flag.flag-om {
  background-position: -144px -240px;
}

.flag.flag-pa {
  background-position: -168px -240px;
}

.flag.flag-pe {
  background-position: -192px -240px;
}

.flag.flag-pf {
  background-position: -216px -240px;
}

.flag.flag-pg {
  background-position: -240px -240px;
}

.flag.flag-ph {
  background-position: -264px -240px;
}

.flag.flag-pk {
  background-position: -288px -240px;
}

.flag.flag-pl {
  background-position: -312px -240px;
}

.flag.flag-pn {
  background-position: -336px -240px;
}

.flag.flag-pr {
  background-position: -360px -240px;
}

.flag.flag-ps {
  background-position: 0 -264px;
}

.flag.flag-pt {
  background-position: -24px -264px;
}

.flag.flag-pw {
  background-position: -48px -264px;
}

.flag.flag-py {
  background-position: -72px -264px;
}

.flag.flag-qa {
  background-position: -96px -264px;
}

.flag.flag-ro {
  background-position: -120px -264px;
}

.flag.flag-rs {
  background-position: -144px -264px;
}

.flag.flag-ru {
  background-position: -168px -264px;
}

.flag.flag-rw {
  background-position: -192px -264px;
}

.flag.flag-sa {
  background-position: -216px -264px;
}

.flag.flag-sb {
  background-position: -240px -264px;
}

.flag.flag-sc {
  background-position: -264px -264px;
}

.flag.flag-sd {
  background-position: -288px -264px;
}

.flag.flag-se {
  background-position: -312px -264px;
}

.flag.flag-sg {
  background-position: -336px -264px;
}

.flag.flag-sh {
  background-position: -360px -264px;
}

.flag.flag-si {
  background-position: 0 -288px;
}

.flag.flag-sk {
  background-position: -24px -288px;
}

.flag.flag-sl {
  background-position: -48px -288px;
}

.flag.flag-sm {
  background-position: -72px -288px;
}

.flag.flag-sn {
  background-position: -96px -288px;
}

.flag.flag-so {
  background-position: -120px -288px;
}

.flag.flag-sr {
  background-position: -144px -288px;
}

.flag.flag-ss {
  background-position: -168px -288px;
}

.flag.flag-st {
  background-position: -192px -288px;
}

.flag.flag-sv {
  background-position: -216px -288px;
}

.flag.flag-sy {
  background-position: -240px -288px;
}

.flag.flag-sz {
  background-position: -264px -288px;
}

.flag.flag-tc {
  background-position: -288px -288px;
}

.flag.flag-td {
  background-position: -312px -288px;
}

.flag.flag-tf {
  background-position: -336px -288px;
}

.flag.flag-tg {
  background-position: -360px -288px;
}

.flag.flag-th {
  background-position: 0 -312px;
}

.flag.flag-tj {
  background-position: -24px -312px;
}

.flag.flag-tk {
  background-position: -48px -312px;
}

.flag.flag-tl {
  background-position: -72px -312px;
}

.flag.flag-tm {
  background-position: -96px -312px;
}

.flag.flag-tn {
  background-position: -120px -312px;
}

.flag.flag-to {
  background-position: -144px -312px;
}

.flag.flag-tr {
  background-position: -168px -312px;
}

.flag.flag-tt {
  background-position: -192px -312px;
}

.flag.flag-tv {
  background-position: -216px -312px;
}

.flag.flag-tw {
  background-position: -240px -312px;
}

.flag.flag-tz {
  background-position: -264px -312px;
}

.flag.flag-ua {
  background-position: -288px -312px;
}

.flag.flag-ug {
  background-position: -312px -312px;
}

.flag.flag-us {
  background-position: -336px -312px;
}

.flag.flag-uy {
  background-position: -360px -312px;
}

.flag.flag-uz {
  background-position: 0 -336px;
}

.flag.flag-va {
  background-position: -24px -336px;
}

.flag.flag-vc {
  background-position: -48px -336px;
}

.flag.flag-ve {
  background-position: -72px -336px;
}

.flag.flag-vg {
  background-position: -96px -336px;
}

.flag.flag-vi {
  background-position: -120px -336px;
}

.flag.flag-vn {
  background-position: -144px -336px;
}

.flag.flag-vu {
  background-position: -168px -336px;
}

.flag.flag-wf {
  background-position: -192px -336px;
}

.flag.flag-ws {
  background-position: -216px -336px;
}

.flag.flag-ye {
  background-position: -240px -336px;
}

.flag.flag-yt {
  background-position: -264px -336px;
}

.flag.flag-za {
  background-position: -288px -336px;
}

.flag.flag-zm {
  background-position: -312px -336px;
}

.flag.flag-zw {
  background-position: -336px -336px;
}